@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

.arrow {
  position: absolute;
  top: 50%;
  cursor: pointer;
}

.right {
  @extend .arrow;
  right: 0;
  @include media-breakpoint-down(sm) { 
    transform: translateY(-20px) translateX(-10px);
  }
}

.left {
  @extend .arrow;
  transform: rotateY(180deg);
  left: 0;
  z-index: 2;
  @include media-breakpoint-down(sm) { 
    transform: translateY(-20px) translateX(10px) rotateY(180deg);
  }
} 

.item {
  @include media-breakpoint-up(sm) { 
    padding: 100px;
    padding-bottom: 50px;
  }

  padding-top: 0;
  img {
    border-radius: 50px;
  }
}