@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

$primary: red;

/* Freight Text Pro Light */
@font-face {
  font-family: 'Freight Text Pro';
  font-weight: 300;
  /* Light */
  font-style: normal;
  src: url('./assets/fonts/Freight/FreightTextProLight-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Freight Text Pro';
  font-weight: 300;
  /* Light */
  font-style: italic;
  src: url('./assets/fonts/Freight/FreightTextProLight-Italic.otf') format('opentype');
}

/* Freight Text Pro Semibold */
@font-face {
  font-family: 'Freight Text Pro';
  font-weight: 600;
  /* Semibold */
  font-style: normal;
  src: url('./assets/fonts/Freight/FreightTextProSemibold-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Freight Text Pro';
  font-weight: 600;
  /* Semibold */
  font-style: italic;
  src: url('./assets/fonts/Freight/FreightTextProSemibold-Italic.otf') format('opentype');
}

/* Gotham Book */
@font-face {
  font-family: 'Gotham';
  font-weight: 400;
  /* Regular */
  font-style: normal;
  src: url('./assets/fonts/Gotham/Gotham-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 400;
  /* Regular */
  font-style: italic;
  src: url('./assets/fonts/Gotham/Gotham-BookIta.otf') format('opentype');
}

/* Gotham Bold */
@font-face {
  font-family: 'Gotham';
  font-weight: 700;
  /* Bold */
  font-style: normal;
  src: url('./assets/fonts/Gotham/Gotham-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 700;
  /* Bold */
  font-style: italic;
  src: url('./assets/fonts/Gotham/Gotham-BoldIta.otf') format('opentype');
}

/* Headings */
h1 {
  font-family: 'Freight Text Pro', serif;
  font-weight: 300;
  font-size: 40px;
  line-height: 45px;
  @include media-breakpoint-up(sm) { 
    font-size: 48px;
    line-height: 52px;
  }
}

h2 {
  font-family: 'Freight Text Pro', serif;
  font-weight: 300; /* Light */
  font-size: 38px;
  line-height: 42px;
}

h3 {
  font-family: 'Freight Text Pro', serif;
  font-weight: 600; /* Semibold */
  font-size: 26px;
  
  line-height: 30px;
}

h4 {
  font-family: 'Freight Text Pro', serif;
  font-weight: 600; /* Semibold */
  font-size: 20px;
  line-height: 22px;
}

h5 {
  font-family: 'Gotham', sans-serif;
  font-weight: 700; /* Regular */
  font-size: 18px;
  line-height: 21.5px;
}

h6 {
  font-family: 'Gotham', sans-serif;
  font-weight: 700; /* Bold */
  font-size: 14px;
  line-height: 18px;
}

/* Paragraphs */
p {
  font-family: 'Gotham', sans-serif;
  font-weight: 400; /* Regular */
  font-size: 14px;
  line-height: 18px;
}

b {
  font-weight: 700;
  &.semi {
    font-weight: 600;
  }
}

.z-index-1 {
  z-index: 1;
}
.cursor-pointer {
  cursor: pointer
}

body {
  font-feature-settings: 'lnum'

}