.image {
  object-fit: contain;
}
.left {
  @extend .image;
  width: 500px;
  max-width: 40%;
  margin-right: auto
}

.right {
  @extend .image;
  width: 400px;
  max-width: 30%;
  margin-top: 150px;
  margin-left: auto;
}

.section {
  min-height: 90vh;
  padding-top: 100px;
}

