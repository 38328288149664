.carousel.carousel-slider {
  margin-left: auto;
  margin-right: auto;

  .control-dots {
    display: flex;
    justify-content: center;
    .dot {
      transition: width .4s ease, height .4s ease;
      width: 10px;
      height: 10px;
      background-color: black;
      box-shadow: none;
      margin-bottom: auto;
      margin-top: auto;
      opacity: 1;
      &.selected {
        background-color: red;
        width: 13px;
        height: 13px;
      }
    }
  }
}