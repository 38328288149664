.input {
  border-width: 0px;
  border-bottom: 2px solid black;
  outline: 0;
}

.label {
  margin:0;
  transition: all .5s ease;
  transform: translateY(15px);
}
.active {
  transform: translateY(0);
}

.bold {
  font-weight: bold;
}