.logo {
  max-width: 225px;
}

.submit {
  position: absolute;
  right: -40px;
  bottom: 0;
  cursor: pointer;
  transition: all .2s ease;
  &:hover {
    transform: translateX(3px);
  }
}

.light {
  font-weight: 300;
}

.social {
  transition: all .4s ease;
  fill:#f0f0f0;
  cursor: pointer;
  &:hover {
    fill: red;
  }
}

.input {
  width: calc(100% - 40px);
}